<template>
  <div>
    <div class="table">
      <ul class="tableHeader flexAndCenter">
        <li class="checkAll" v-show="form.goods_status==='普通仓库'">{{ $fanyi("选择") }}</li>
        <li class="number">{{ $fanyi("番号") }}</li>
        <li class="mirror">{{ $fanyi("照片") }}</li>
        <li class="newGoodsTitle">{{ $fanyi("商品规格") }}</li>
        <li class="goodsAttribute">{{ $fanyi("购入数据") }}</li>
        <li :class="form.goods_status==='普通仓库'?'remark':'newRemark'">{{ $fanyi("備考欄") }}</li>
        <li :class="form.goods_status==='普通仓库'?'option':'newOption'">{{ $fanyi("附加服务") }}</li>
        <li class="tag">{{ $fanyi("标签设置") }}</li>
        <li class="goodsProperty" v-if="form.goods_status==='普通仓库'">{{ $fanyi("已提出数") }}</li>
        <li class="quantityOnHand" style="border-top-right-radius:10px;"
            :style="form.goods_status==='普通仓库'?'':'width: 250px'">
          {{ form.goods_status === '普通仓库' ? $fanyi("发送可能数") : $fanyi("装箱数量") }}
        </li>
      </ul>
      <div class="stockAutoListContainer">
        <div v-for="(bitem, bindex) in stockAutoList">
          <div :class="form.goods_status==='收费仓库'?'tollWarehouseContainer':''">
            <div class="shopDetailContainer flexAndCenter">
              <input type="checkbox" style="margin-left: 20px"
                     v-show="form.goods_status==='普通仓库'"
                     @change="shopAll(bindex)"
                     v-model="bitem.checked"
              />
              <div class="fontSize14 color333 fontWeightBold" style="margin-left:10px">
                {{ $fanyi("订单号") }}：{{ bitem.order_sn }}
              </div>
            </div>
            <div class="goodsConUlContainer">
              <ul v-for="(item,itemIndex) in bitem.order_detail" :key="item.id" class="goodsConUl flexAndCenter">
                <li class="checkAll" v-show="form.goods_status==='普通仓库'">
                  <input type="checkbox" style="margin-left: 0"
                         @change="radio(bindex,itemIndex)"
                         v-model="item.checked"
                  />
                </li>
                <li class="number">
                  {{ item.sorting }}
                </li>
                <li class="mirror">
                  <el-popover placement="right" trigger="hover">
                    <img :src="item.pic"
                         @click="$fun.toCommodityDetails(item.goods_id,item.from_platform)"
                         style="width: 300px; height: 300px"
                    />
                    <img :src="item.pic"
                         slot="reference"
                         style="width: 60px;height: 60px"
                    />
                  </el-popover>
                </li>
                <li class="newGoodsTitle">
                  <el-popover trigger="hover">
                    <div class="showDetailCon">
                      <p v-for="(bitem, bindex) in item.detail"
                         :key="bitem.key + bindex"
                         :title="bitem.key + ':' + bitem.value">
                        {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                      </p>
                    </div>
                    <div slot="reference" class="showDetailCon">
                      <div class="flex" style="justify-content: center;flex-direction: column">
                        <p v-for="(bitem, bindex) in item.detail"
                           :key="bitem.key + bindex"
                           :title="bitem.key + ':' + bitem.value">
                          {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                        </p>
                      </div>
                    </div>
                  </el-popover>
                </li>
                <li class="goodsAttribute flexAndCenter">
                  <div class="newRemarkData">
                    <div>{{ $fanyi("数量") }}：{{ item.confirm_num }}</div>
                    <div>{{ $fanyi("单价") }}：{{ item.confirm_price }}{{ $fanyi("元") }}</div>
                    <div>{{ $fanyi("国内运费") }}：{{ item.confirm_freight }}{{ $fanyi("元") }}</div>
                    <div>{{ $fanyi("小计") }}：
                      {{ Number(item.confirm_num) * Number(item.confirm_price) }}{{ $fanyi("元") }}
                    </div>
                  </div>
                </li>
                <li :class="form.goods_status==='普通仓库'?'remark flexAndCenter':'newRemark flexAndCenter'">
                  <el-input
                      resize="none"
                      v-model="item.client_remark"
                      readonly
                      type="textarea">
                  </el-input>
                </li>
                <li :class="form.goods_status==='普通仓库'?'option':'newOption'">
                  <el-popover trigger="hover">
                    <div v-for="(optionItem,optionIndex) in item.option" :key="optionIndex"
                         style="text-align: left;">
                      <div v-if="optionItem.num>0&&optionItem.checked===true">
                        {{ optionIndex + 1 }}.{{ optionItem.name_translate }} *{{ optionItem.num }}
                      </div>
                    </div>
                    <div slot="reference">
                      <div class="optionHiddenContainer">
                        <div v-for="(optionItem,optionIndex) in item.option" :key="optionIndex"
                             style="text-align: left;width:149px;cursor: pointer">
                          <div v-if="optionItem.num>0&&optionItem.checked===true">
                            {{ optionIndex + 1 }}.{{ optionItem.name_translate }} *{{ optionItem.num }}
                          </div>
                        </div>
                      </div>
                      <div v-show="item.optionCheckedNum>3" style="text-align: left">........</div>
                    </div>
                  </el-popover>
                </li>
                <li class="tag">
                  <el-popover trigger="hover">
                    <div class="orderTagPopoverShowContainer">
                      <div v-for="(tagItem,tagIndex) in item.order_detail_tag" :key="tagIndex" class="tagList">
                        <div v-show="tagItem.no!==''||tagItem.goods_no!==''">
                          <div class="color333 fontSize14">{{ tagItem.type_translate }}</div>
                          <div class="color333 fontSize14">{{ tagItem.no }}</div>
                          <div class="color333 fontSize14">{{ tagItem.goods_no }}</div>
                        </div>
                      </div>
                    </div>
                    <div slot="reference" class="orderTagContainer">
                      <div v-for="(tagItem,tagIndex) in item.order_detail_tag" :key="tagIndex" class="tagList">
                        <div v-show="tagItem.no!==''||tagItem.goods_no!==''">
                          <div class="color333 fontSize14">{{ tagItem.type_translate }}</div>
                          <div class="color333 fontSize14" style="word-wrap: break-word">{{ tagItem.no }}</div>
                          <div class="color333 fontSize14" style="word-wrap: break-word">{{
                              tagItem.goods_no
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-popover>
                </li>
                <li class="goodsProperty" v-if="form.goods_status==='普通仓库'">{{ item.send_success_num }}</li>
                <li class="quantityOnHand" :style="form.goods_status==='普通仓库'?'':'width: 250px'">
                  {{ form.goods_status === '普通仓库' ? item.send_await_num : item.is_box_num }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="operatingOptions flexAndCenter">
      <div class="flexAndCenter">
        <div class="flexAndCenter">
          <input type="checkbox" style="margin-left: 20px" @change="allChecked" v-model="checked"/>
          <div class="fontSize14 color333" style="margin-left:10px">{{ $fanyi("全选") }}</div>
        </div>
        <div class="flexAndCenter margin-left-60">{{ $fanyi("已选择") }}
          <span class="colorB4272B fontWeightBold">{{ total }}</span>
          {{ $fanyi("件") }}
        </div>
      </div>
      <div class="submitDeliveryOrderBtn" @click="pushDeliveryOrder">{{ $fanyi("追加到配送单") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "commonWarehouse",
  props: {
    ids: {//当前订单页面的id集合
      type: String
    },
    timer: {//刷新子组件
      type: String
    },
    idsCount: {//当前配送单的长度
      type: Number
    }
  },
  data() {
    return {
      stockAutoList: [],
      form: {
        goods_status: '普通仓库'
      },
      total: 0,
      checked: false,
    }
  },
  created() {
    this.getCommonWarehouseList();
  },
  methods: {
    //获取过滤后的普通仓库列表
    getCommonWarehouseList() {
      this.$api.porderPreviewAddBeStockAuto({overlook_order_detail_id: this.ids}).then((res) => {
        res.data.forEach((item) => {
          item.checked = false;
          item.order_detail.forEach((items) => {
            items.checked = false;
            items.optionCheckedNum = 0;
            if (items.option != null) {
              items.option.forEach((optionItem) => {
                if (optionItem.num > 0 && optionItem.checked === true) {
                  items.optionCheckedNum++
                }
              })
            }
          })
        })
        this.stockAutoList = res.data;
      });
    },
    //订单下的商品全选或取消全选
    shopAll(index) {
      this.total = 0;
      let count = 0;
      this.stockAutoList[index].order_detail.forEach((item) => {
        item.checked = this.stockAutoList[index].checked;
      })
      this.stockAutoList.forEach((item, index) => {
        item.order_detail.forEach((items, itemsIndex) => {
          let itemsCount = 0;
          if (item.checked === true) {
            this.total++
            itemsCount++
            if (itemsCount === item.order_detail.length) {
              item.checked = true
            }
          }
        })
        if (item.checked === true) {
          count++
          if (count === this.stockAutoList.length) {
            this.checked = true
          }
        }
      })
    },
    //单选处理
    radio(bindex, itemIndex) {
      let orderCount = 0;//订单选中数量
      this.total = 0;
      this.stockAutoList.forEach((item, index) => {
        let count = 0;//商品选中数量
        item.order_detail.forEach((items, itemsIndex) => {
          //商品选中时总商品选中数量增加
          if (items.checked === true) {
            this.total++
            count++
          }
          item.checked = count === item.order_detail.length;
        })
        if (item.checked === true) {
          orderCount++
        }
        this.checked = orderCount === this.stockAutoList.length;
      })
    },
    //追加到配送单
    pushDeliveryOrder() {
      let arr = [];
      this.stockAutoList.forEach((item) => {
        item.order_detail.forEach((items) => {
          items.newTagTable = [];
          if (items.checked === true) {
            arr.push(items)
          }
        })
      })
      if (this.idsCount > 200 || this.idsCount + arr.length > 200 || arr.length > 200) {
        return this.$message.warning(this.$fanyi("一个订单最多添加200个商品"));
      }
      arr.forEach((item) => {
        item.checked = false;
        item.value = item.send_await_num;
      })
      this.$emit("cartAddGoodsToOrder", arr);
    },
    //全选处理
    allChecked() {
      this.total = 0;
      this.stockAutoList.forEach((item) => {
        item.checked = this.checked
        item.order_detail.forEach((itemss) => {
          itemss.checked = this.checked;
          itemss.checked ? this.total++ : '';
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.table {
  margin-top: 26px;
  padding: 0 26px 30px;

  .checkAll {
    width: 50px;
  }

  .number {
    width: 50px;
  }

  .managementNumber {
    width: 120px;
  }

  .mirror {
    width: 88px;
  }

  .price {
    width: 100px;
  }

  .goodsTitle {
    width: 220px;

    div {
      width: 193px;
      line-height: 20px;
      text-align: left;
    }
  }

  .newGoodsTitle {
    width: 240px;


    .u-line-3 {
      width: 143px;
      line-height: 20px;
    }

    .attributeContainer {
      padding: 0 20px;

    }

    .newRemarkData {
      padding: 29px 0;

      div {
        text-align: left;
        color: #333333;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .subtotal {
    width: 80px;
  }

  .remark {
    width: 180px;

    /deep/ .el-textarea {
      display: flex;
      align-items: center;
      justify-content: center;

      .el-textarea__inner {
        width: 140px;
        height: 100px;
      }
    }
  }

  .newRemark {
    width: 230px;

    /deep/ .el-textarea {
      display: flex;
      align-items: center;
      justify-content: center;

      .el-textarea__inner {
        width: 190px;
        height: 100px;
      }
    }
  }

  .option {
    width: 160px;
  }

  .newOption {
    width: 210px;
  }

  .optionHiddenContainer {
    height: 60px !important;
    overflow: hidden;
  }

  .goodsProperty {
    width: 100px;
  }

  .quantityOnHand {
    width: 100px;
    background: #F8E2E2 !important;
  }


  .goodsInWarehouseQuantity {
    width: 160px;
  }

  .goodsAttribute {
    width: 160px;
    justify-content: center;

    .attributeContainer {
      padding: 0 20px;

    }

    .newRemarkData {
      padding: 29px 0;

      div {
        text-align: left;
        color: #333333;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .tagSetting {
    width: 140px;
  }


  .operation {
    width: 112px;

    .btnList {
      padding: 0 15px;
      justify-content: space-between;

      div {
        text-decoration: underline;
        font-size: 14px;
        cursor: pointer;
      }

      div:first-child {
        color: #B4272B;
      }

      div:last-child {
        color: #2742B4;
      }
    }
  }

  .tableHeader {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #F0F0F0;
    height: 56px;
    font-size: 14px;
    line-height: 56px;
    text-align: center;
    color: #333;
  }

  .stockAutoListContainer {
    max-height: 600px;
    overflow: scroll
  }

  .stockAutoListContainer::-webkit-scrollbar {
    width: 0;
  }

  .margin-left-60 {
    margin-left: 60px;
  }

  .tableFooter {
    border-bottom: 1px solid #DFDFDF;
    height: 120px;
    font-size: 14px;
    line-height: 120px;
    text-align: center;
    color: #333;
  }

  .boxMessageContainer {
    height: 60px;

    .boxMessageValue {
      color: #B4272B;
      margin-right: 4px;
    }
  }

  .tollWarehouseContainer {
    border-radius: 10px;
    border: 1px solid #DFDFDF;
    overflow: hidden;
  }

  .shopDetailContainer {
    height: 60px;
    min-width: 60px;
    background-color: #fff;
  }

  .goodsConUlContainer {
    border-radius: 10px;
    overflow: hidden;
  }

  .goodsConUl {
    //height: 120px;
    background: #F9F9F9;
    width: 100%;

    li {
      text-align: center;
      font-size: 14px;
      color: #333;
      background: #F9F9F9;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      //line-height: 120px;
    }

    .showDetailCon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 160px;

      p {
        width: 100%;
        text-align: left;
      }
    }
  }
}

.tag {
  width: 160px;

  .tagList {
    width: 160px;

    div {
      width: 160px;
      text-align: left;
      line-height: 20px;
      padding-left: 10px;
    }
  }
}

.operatingOptions {
  width: 1328px;
  height: 70px;
  position: sticky;
  right: 0;
  left: 0;
  z-index: 10;
  bottom: 0;
  border: 1px solid #e8e8e8;
  // height: 106px;
  background: #ffffff;
  box-shadow: 0 0 20px 0 rgba(10, 11, 34, 0.05);
  border-radius: 10px 10px 0 0;
  padding: 10px 26px 10px 0;
  justify-content: space-between;

  .fontWeightBold {
    margin: 0 8px;
  }

  .submitDeliveryOrderBtn {
    width: 120px;
    height: 34px;
    background: #B4272B;
    border-radius: 8px;
    line-height: 34px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
  }
}

input[type="checkbox"]:checked {
  background: #4A91E9;
  border: none;
}
</style>
