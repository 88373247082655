<template>
  <div element-loading-text="" element-loading-background="rgba(0, 0, 0, 0.3)" class="orderPage"
       v-loading.fullscreen.lock="fullscreenLoading">
    <div class="main zhu base-w">
      <!-- 头部 -->
      <div class="head">
        <span class="flexAndCenter">
          <font class="headT1">{{ $fanyi("配送单号") }}：</font><span class="headT1">{{ datas.porder_sn }}</span>
        </span>
      </div>
      <!-- 订单列表 -->
      <div class="order">
        <ul class="header flex">
          <li class="inputBox">
            No
          </li>
          <li class="inputBox"
              v-show="$route.query.type === 'temporary' || datas.porder_status === 10 || (datas.porder_status === 20 && datas.is_y_editing === 0)">
            {{ $fanyi("选择") }}
          </li>
          <li :class="$route.query.type === 'temporary' || datas.porder_status === 10 ? 'orderNumber' : 'newOrderNumber'"
              style="min-width: 115px">
            {{ $fanyi("订单号") }}
          </li>
          <li class="inputBox" style="min-width: 60px">
            {{ $fanyi("番号") }}
          </li>
          <li class="mirror">{{ $fanyi("写真") }}</li>
          <li
              :class="$route.query.type === 'temporary' || datas.porder_status === 10 ? 'goodsSpecification' : 'newGoodsSpecification'">
            {{ $fanyi("商品详细") }}
          </li>
          <li
              :class="$route.query.type === 'temporary' || datas.porder_status === 10 ? 'goodsSpecification' : 'newGoodsSpecification'">
            <span>{{ $fanyi("购入数据") }}</span>
          </li>
          <li class="handQuantity" style="min-width: 148px">
            {{ $fanyi("标签信息") }}
          </li>
          <li class="handQuantity"
              v-show="$route.query.type === 'temporary' || datas.porder_status === 10 || (datas.porder_status === 20 && datas.is_y_editing === 0)">
            {{ $fanyi("可提出数量") }}
          </li>
          <li class="deliveryQuantity">{{ $fanyi("提货数量") }}</li>
          <li class="remark" style="line-height: 56px!important;"
              :style="$route.query.id != undefined ? 'width:220px;' : ''">
            {{ $fanyi("单番装箱备注") }}
          </li>
          <li class="remark" style="line-height: 56px!important;">
            {{ $fanyi("スタッフ") }}
          </li>
        </ul>
        <div class="goodsBox">
          <ul v-for="(item, itemIndex) in newList" :key="item.id" :class="`sorting${item.sorting}`"
              class="goodsConUl flexAndCenter">
            <li class="inputBox flexAndCenterAndCenter cursorPointer" style="flex-direction: column"
                @click="getEditSnInfo(item)">
              <div>
                <div>{{ itemIndex + 1 }}</div>
                <div v-if="item.id!=snInfo.id" :title="item.for_sn" class="u-line" style="width: 40px">
                  {{ item.for_sn }}
                </div>
                <el-input v-else v-model="item.for_sn" :placeholder="$fanyi('管理番号')" size="mini"
                          @blur="updateForSn(item)"
                          @input="$forceUpdate()"/>
              </div>
            </li>
            <li class="inputBox flexAndCenterAndCenter"
                v-show="$route.query.type === 'temporary' || datas.porder_status === 10 || (datas.porder_status === 20 && datas.is_y_editing === 0)">
              <div class="flexAndCenterAndCenter" style="flex-direction: column">
                <el-popover placement="right" title="合计数量" trigger="focus" width="200">
                  <statistics :checkedList="checkedList"/>
                  <el-checkbox slot="reference"
                               v-model="item.checked"
                               style="margin:0"
                               type="checkbox"
                               @change="radio(itemIndex)"
                  />
                </el-popover>
              </div>
            </li>
            <li :class="$route.query.type === 'temporary' || datas.porder_status === 10 ? 'orderNumber flexAndCenterAndCenter cursorPointer' : 'newOrderNumber flexAndCenterAndCenter cursorPointer'">
              <router-link target="_blank" class="colorB4272B cursorPointer" style="text-decoration: underline;"
                           :to="{ path: '/OrderDetails', query: { order_sn: item.order_sn } }">
                {{ item.order_sn }}
              </router-link>
            </li>
            <li class="inputBox flexAndCenterAndCenter">
              {{ item.sorting }}
            </li>
            <li class="mirror">
              <el-popover placement="right" trigger="hover" class="flexAndCenterAndCenter">
                <img :src="item.pic" style="width: 300px; height: 300px"/>
                <router-link slot="reference" target="_blank"
                             :to="{ path: '/ProductDetails', query: { goods_id: item.goods_id, fromPlatform: item.from_platform } }">
                  <img :src="item.pic" style="width: 60px;height: 60px;margin-top: 30px;"/>
                </router-link>
              </el-popover>
            </li>
            <li
                :class="$route.query.type === 'temporary' || datas.porder_status === 10 ? 'goodsSpecification flexAndCenterAndCenter' : 'newGoodsSpecification flexAndCenterAndCenter'">
              <div class="showDetail">
                <el-popover trigger="hover" placement="top">
                  <div class="showDetailCon u-line">
                    <p v-for="(bitem, bindex) in item.detail" :key="bitem.key + bindex"
                       :title="bitem.key + ':' + bitem.value" class="goodsDetailOpt ispay">
                      {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                    </p>
                  </div>
                  <div class="showDetailCon u-line" slot="reference">
                    <p v-for="(bitem, bindex) in item.detail" :key="bitem.key + bindex"
                       :title="bitem.key + ':' + bitem.value" class="goodsDetailOpt ispay u-line">
                      {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                    </p>
                  </div>
                </el-popover>
              </div>
            </li>
            <li
                :class="$route.query.type === 'temporary' || datas.porder_status === 10 ? 'goodsSpecification flexAndCenterAndCenter' : 'newGoodsSpecification flexAndCenterAndCenter'">
              <div class="purchaseData">
                <div>{{ $fanyi("数量") }}：{{ item.offer_num_bak }}</div>
                <div>{{ $fanyi("单价") }}：{{ item.confirm_price }}{{
                    $fanyi("元")
                  }}（{{
                    $fun.yenNumSegmentation(Number(Number(item.confirm_price) * Number(exchangeRate)).toFixed(0))
                  }}円）
                </div>
                <div>{{ $fanyi("国内运费") }}：{{ Number(item.confirm_freight).toFixed(0) }}{{ $fanyi("元") }}
                  （{{
                    $fun.yenNumSegmentation(Number(Number(item.confirm_freight) * Number(exchangeRate)).toFixed(0))
                  }}円）
                </div>
              </div>
            </li>
            <li class="handQuantity" style="flex-direction: column;padding: 15px 0; "
                v-if="datas.porder_status === 20 && datas.is_y_editing === 1">
              <div class="tagSettingTableContainer"
                   v-for="(tagItem, tagIndex) in item.newTagTable"
                   :key="tagIndex" :style="tagIndex === item.newTagTable.length - 1 ? '' : 'margin-bottom: 10px'"
                   style="border-bottom: none">
                <div class="tagTableContainer flexAndCenter" v-for="(newTagItem, newTagIndex) in tagItem.table"
                     v-if="newTagItem.key != 'type'&&newTagItem.key!='text_line_four'&&newTagItem.key!='text_line_two'&&newTagItem.key!='text_line_one'&&newTagItem.key!='text_line_three'"
                     :key="newTagIndex">
                  <div :title="newTagItem.value" class="u-line cursorPointer">{{ newTagItem.value }}</div>
                </div>
              </div>
            </li>
            <li class="handQuantity" style="flex-direction: column;padding: 15px 0; " v-else>
              <div v-if="item.newTagTable.length > 0" @click="getGoodsTag(item)">
                <div class="tagSettingTableContainer"
                     v-for="(tagItem, tagIndex) in item.newTagTable"
                     :key="tagIndex" :style="tagIndex === item.newTagTable.length - 1 ? '' : 'margin-bottom: 10px'"
                     style="border-bottom: none">
                  <div class="tagTableContainer flexAndCenter" v-for="(newTagItem, newTagIndex) in tagItem.table"
                       v-if="newTagItem.key != 'type'&&newTagItem.key!='text_line_four'&&newTagItem.key!='text_line_two'&&newTagItem.key!='text_line_one'&&newTagItem.key!='text_line_three'"
                       :key="newTagIndex">
                    <div :title="newTagItem.value" class="u-line cursorPointer">{{ newTagItem.value }}</div>
                  </div>
                </div>
              </div>
              <div v-else class="selectTagText" @click="getGoodsTag(item)"></div>
            </li>
            <li class="handQuantity flexAndCenterAndCenter"
                v-show="$route.query.type === 'temporary' || datas.porder_status === 10 || (datas.porder_status === 20 && datas.is_y_editing === 0)">
              {{ item.send_await_num }}
            </li>
            <li :style="($route.query.type === 'temporary'&&item.value!=item.send_await_num) || (datas.porder_status === 10&&item.value!=item.send_await_num) || (datas.porder_status === 20 && datas.is_y_editing === 0&&item.value!=item.send_await_num)?'background:#faf2f2':''"
                class="deliveryQuantity flexAndCenterAndCenter">
              <el-input v-model="item.value" type="number"
                        v-if="$route.query.type === 'temporary' || datas.porder_status === 10 || (datas.porder_status === 20 && datas.is_y_editing === 0)"
                        @input="numTrunc($event,item)" @keydown.native="newChannelInputLimit"/>
              <div v-else>{{ item.send_num }}</div>
            </li>
            <li class="remark flexAndCenterAndCenter">
              <el-input resize="none" v-model="item.client_remark" @input="textareaChange"
                        v-if="$route.query.type === 'temporary' || datas.porder_status === 10 || (datas.porder_status === 20 && datas.is_y_editing === 0)"
                        type="textarea"/>
              <div v-else>{{ item.client_remark }}</div>
            </li>
            <li class="remark flexAndCenterAndCenter">{{ item.y_reply }}</li>
          </ul>
        </div>
        <addressInfo v-if="timer != null" :key="timer" ref="addressInfo" :deliveryDetails="datas"
                     :status="orderStatusName" style="margin-top: 52px"/>
      </div>
    </div>
    <div class="operatingOptions flexAndCenter"
         :style="datas.porder_status!=undefined&&datas.porder_status!==10 && datas.is_y_editing === 1 ? 'justify-content: flex-start;' : ''">
      <div
          v-show="$route.query.type === 'temporary' || (datas.porder_status === 10 || (datas.porder_status === 20 && datas.is_y_editing === 0))"
          class="choies flexAndCenter">
        <input id="" v-model="checked" name="" type="checkbox" @change="allChoice"/>
        <button class="qx" @click="checked = !checked; allChoice();">
          {{ $fanyi("全选") }}
        </button>
        <div class="AdditionalBtn" style="margin-right: 26px;" @click="showAddCommonWarehouseGoodsToPorderDialog">
          {{ $fanyi("追加") }}
        </div>
        <div v-if="datas.amazon_authorization==true" class="AdditionalBtn"
             style="width: 120px;margin-right: 26px;"
             @click="markerAmazon">
          亚马逊入仓商品
        </div>
        <div class="deleteText" @click="delOrder">{{ $fanyi("删除") }}</div>
      </div>
      <div class="choies flexAndCenter">
        <div v-if="(datas.porder_status === 20 && datas.is_y_editing === 1)||datas.porder_status>20"
             class="flexAndCenter">
          <input id="" v-model="checked" name="" type="checkbox" @change="allChoice"/>
          <button class="qx" @click="checked = !checked; allChoice();">
            {{ $fanyi("全选") }}
          </button>
          <div v-if="datas.amazon_authorization==true" class="AdditionalBtn"
               style="width: 120px;margin-right: 26px;"
               @click="markerAmazon">
            亚马逊入仓商品
          </div>
        </div>
        <div class=" goodsNum flexAndCenter">
          <div>{{ $fanyi("商品种类") }}</div>
          ：
          <div>{{ newList.length }}</div>
        </div>
        <div class="margin-left-52 goodsNum flexAndCenter">
          <div>{{ $fanyi("提出数量") }}</div>
          ：
          <div>{{ submitNum }}</div>
        </div>
        <div>（1{{ $fanyi('元') }}={{ exchangeRate }}{{ $fanyi('円') }}）</div>
        <div>合計商品代金：{{ $fun.yenNumSegmentation(goodsPrice) }}&nbsp;円</div>
      </div>
      <div
          v-show="$route.query.type === 'temporary' || (datas.porder_status === 10 || (datas.porder_status === 20 && datas.is_y_editing === 0))"
          class="buttonGroup">
        <button @click="OrdersSubmitted('save')" v-show="datas.porder_status < 20 || datas.porder_status == undefined">
          {{ $fanyi("临时保存") }}
        </button>
        <button @click="OrdersSubmitted('send')">{{ $fanyi("提出配送单") }}</button>
      </div>
    </div>
    <div class="addCartGoodsToOrderContainer">
      <el-dialog :before-close="handleClose" :close-on-click-modal="false" :close-on-press-escape="false"
                 :visible.sync="addCartGoodsToOrderDialogVisible"
                 center title="倉庫" width="1328px">
        <commonWarehouseList :key="timer" :ids="ids" :idsCount="idsCount" @cartAddGoodsToOrder="cartAddGoodsToOrder"/>
      </el-dialog>
      <!--        tag编辑-->
      <div class="goodsAttributeEditContainer">
        <el-dialog :visible.sync="selectTagDialogVisible" width="1030px" :title="$fanyi('标签设置')"
                   :close-on-click-modal="false" :close-on-press-escape="false" center>
          <editTag @saveGoodsTag="saveGoodsTag" :fullscreenLoading="newFullscreenLoading" :key="timer"
                   ref="editTag" :goodsInfo="goodsInfo" :isShow="datas.amazon_authorization" :list="orderTagList"/>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import addressInfo from './addressInfo'
import editTag from '../../../../userOrder/views/components/editTag'
import commonWarehouseList from './commonWarehouseList'
import statistics from "@/views/UserCenter/views/userOrder/views/components/statistics.vue";

export default {
  components: {
    statistics,
    addressInfo,
    editTag,
    commonWarehouseList
  },
  props: {
    orderStatusName: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      datas: {
        address: {
          importer: {},
          receiver: {}
        },
        logistics_group: []
      },
      addCartGoodsToOrderDialogVisible: false,
      newList: [],
      checkedList: [],
      idsCount: 0,
      goodsInfo: {},
      snInfo: {},
      timer: null,
      goodsPrice: 0,
      newFullscreenLoading: false,
      fullscreenLoading: true,
      orderTagList: [],
      submitNum: 0,
      selectTagDialogVisible: false,
      ids: '',
      checked: false,
    }
  },
  computed: {
    ...mapState({
      orderFileList: "fileList",
    }) /* 推荐 */,
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate');
      return Number(exchangeRate)
    }
  },
  mounted() {
    window.addEventListener("load", () => {
      //写入你想要执行的代码
      if (this.orderFileList.length > 0) {
        this.$store.commit("emptyFileList", {length: 0});
      }
    });
    this.orderStatusName === 'temporary' && this.$route.query.id ? this.newOrder() : this.newOfferOrder(this.$route.query.pOrder_sn);
  },
  destroyed() {
    if (this.orderFileList.length > 0) {
      this.$store.commit("emptyFileList", {length: 0});
    }
  },
  methods: {
    // 提交或临时保存
    OrdersSubmitted(type) {
      if (this.newList.length > 200) {
        this.$message.error(this.$fanyi("一个订单最多添加200个商品"));
        return
      }
      this.$refs.addressInfo.$refs.userAddressRef.validate((valid) => {
        if (valid) {
          this.$refs.addressInfo.$refs.importerAddressRef.validate((valid) => {
            if (valid) {
              if ((this.$refs.addressInfo.importerAddressFrom.company !== '' && this.$refs.addressInfo.importerAddressFrom.company != null) && (this.$refs.addressInfo.importerAddressFrom.corporate_name == '' || this.$refs.addressInfo.importerAddressFrom.corporate_name == null)) {
                this.$message({type: "error", message: '会社名と法人番号の両方をご記入下さい。'});
                return
              }
              let porder_detail = [];
              let status = false;
              this.newList.forEach((item) => {
                if (item.value === 0) {
                  status = true
                }
                let arr = [];
                if (item.newTagTable.length > 0) {
                  let value = this.doubleLayerToDoubleLayer(item.newTagTable);
                  value.forEach((valueItem) => {
                    arr.push({
                      type: valueItem.type,
                      no: valueItem.no,
                      goods_no: valueItem.goods_no,
                      type_translate: valueItem.type_translate,
                      text_line_four: valueItem.text_line_four != undefined ? valueItem.text_line_four : '',
                      text_line_one: valueItem.text_line_one != undefined ? valueItem.text_line_one : '',
                      text_line_three: valueItem.text_line_three != undefined ? valueItem.text_line_three : '',
                      text_line_two: valueItem.text_line_two != undefined ? valueItem.text_line_two : ''
                    })
                  })
                }
                porder_detail.push({
                  order_detail_id: item.id != undefined ? item.id : item.order_detail_id,
                  send_num: item.value,
                  client_remark: item.client_remark,
                  porder_detail_tag: arr.length === 0 ? undefined : arr
                })
              })
              if (status === true) {
                this.$message.error(this.$fanyi("提货数量不能为零"));
                return
              }
              this.$refs.addressInfo.userAddressFrom.title = this.$refs.addressInfo.receiverTitle;
              this.$refs.addressInfo.importerAddressFrom.title = this.$refs.addressInfo.importerTitle;
              let datas = {
                create_type: type,
                porder_sn: this.datas.porder_sn,
                logistics_id: this.$refs.addressInfo.selectValue,
                client_remark: this.$refs.addressInfo.textarea2,
                porder_detail: porder_detail,
                porder_file: this.$refs.addressInfo.fileList,
                receiver_address: this.$refs.addressInfo.userAddressFrom,
                importer_address: this.$refs.addressInfo.importerAddressFrom,
              };
              if (this.$route.query.type === 'temporary' || this.datas.porder_status === 10) {
                this.$api.storageSendDeliver(datas).then((res) => {
                  if (res.code != 0) {
                    this.$message.error(this.$fanyi(res.msg));
                    if (res.msg.indexOf('商品番号が提出可能数を超えています。') != -1) {
                      let className = '.sorting' + res.msg.substr(0, res.msg.length - 18);
                      document.querySelector(className).scrollIntoView({block: 'center'})
                    }
                    return
                  }
                  this.$message.success(this.$fanyi(res.msg))
                  // 假如点击的是临时保存按钮
                  if (type == 'save') {
                    this.$fun.routerToPage("/user/deliveryList?status=temporary");
                  }
                  // 假如点击的是提出按钮
                  if (type == 'send') {
                    this.$fun.routerToPage("/user/deliveryList?status=offer");
                  }
                });
              } else {
                this.$api.porderEdit(datas).then((res) => {
                  if (res.code != 0) {
                    this.$message.error(this.$fanyi(res.msg));
                    if (res.msg.indexOf('商品番号が提出可能数を超えています。') != -1) {
                      let className = '.sorting' + res.msg.substr(0, res.msg.length - 18);
                      document.querySelector(className).scrollIntoView({block: 'center'})
                    }
                    return
                  }
                  this.$message.success(this.$fanyi(res.msg))
                  this.$fun.routerToPage("/user/deliveryList?status=offer");
                });
              }
            } else {
              const container = document.getElementsByClassName('main')[0];
              container.scrollIntoView(false);
            }
          })
        } else {
          const container = document.getElementsByClassName('main')[0];
          container.scrollIntoView(false);
        }
      })
    },
    //输入框输入内容刷新
    textareaChange() {
      this.$forceUpdate();
    },
    //获取要编辑的管理番号详情
    getEditSnInfo(val) {
      this.snInfo = val;
    },
    updateForSn(val) {
      if (val.for_sn != '') {
        this.$api.updateOrderDetailForSn({order_detail_id: val.id, for_sn: val.for_sn}).then((res) => {

        });
      }
    },
    markerAmazon() {
      let count = 0;
      let arr = [];
      this.newList.forEach((item) => {
        if (item.checked == true) {
          count++
          if (item.warehousing == undefined) {
            item.warehousing = true
          } else {
            item.warehousing = !item.warehousing
          }
          this.$forceUpdate();
          arr.push({
            order_sn: item.order_sn,
            order_detail_id: [item.id]
          })
        }
      })
      if (count == 0) {
        this.$message.error(this.$fanyi('请至少选择一个商品'))
        return
      }
      arr.forEach((item) => {
        this.$api.markersWarehouseGoods({
          order_sn: item.order_sn,
          order_detail_id: item.order_detail_id
        }).then((res) => {
        })
      })
    },
    //双层数组转一层
    doubleLayerToDoubleLayer(arr) {
      let newArr = [];
      arr.forEach((item) => {
        newArr.push({
          type: item.table[3].value,
          no: item.table[1].value,
          goods_no: item.table[2].value,
          type_translate: item.table[0].value,
          id: item.id,
          text_line_four: item.table[4] != undefined ? item.table[4].value : undefined,
          text_line_one: item.table[5] != undefined ? item.table[5].value : undefined,
          text_line_three: item.table[6] != undefined ? item.table[6].value : undefined,
          text_line_two: item.table[7] != undefined ? item.table[7].value : undefined,
        })
      })
      return newArr
    },
    //一层数组转双层
    oneLayerToTwoLayers(arr) {
      let newArr = [];
      arr.forEach((tagItem) => {
        if (tagItem.no !== '' || tagItem.goods_no !== '') {
          let table = [];
          for (let i = 0; i < tagItem.list.length; i++) {
            if (tagItem.list[i].value === tagItem.type) {
              tagItem.type_translate = tagItem.list[i].label;
              break;
            }
          }
          table.push({key: '类别', value: tagItem.type_translate});
          table.push({key: '标签号', value: tagItem.no});
          table.push({key: '商品码', value: tagItem.goods_no});
          table.push({key: 'type', value: tagItem.type});
          table.push({
            key: 'text_line_four',
            value: tagItem.text_line_four != undefined ? tagItem.text_line_four : undefined
          });
          table.push({
            key: 'text_line_one',
            value: tagItem.text_line_one != undefined ? tagItem.text_line_one : undefined
          });
          table.push({
            key: 'text_line_three',
            value: tagItem.text_line_three != undefined ? tagItem.text_line_three : undefined
          });
          table.push({
            key: 'text_line_two',
            value: tagItem.text_line_two != undefined ? tagItem.text_line_two : undefined
          });
          newArr.push({table: table, id: tagItem.id})
        }
      })
      return newArr
    },
    //获取商品tag
    getGoodsTag(item) {
      this.goodsInfo = {
        goods_id: item.goods_id,
        from_platform: item.from_platform,
        id: item.id
      };
      this.selectTagDialogVisible = true;
      this.$api.getOrderTagList({
        order_detail_id: item.order_detail_id
      }).then((res) => {
        let list = [];
        res.data.forEach((item) => {
          list.push({
            value: item.type,
            label: item.type_translate
          })
        })
        if ((item.order_detail_tag == undefined && item.porder_detail_tag.length === 0) || (item.porder_detail_tag == undefined && item.order_detail_tag.length === 0)) {
          let order_detail_tag = [];
          order_detail_tag.push({
            no: '',
            goods_no: '',
            list: list,
            type: 'FBA'
          })
          setTimeout(() => {
            this.newFullscreenLoading = false;
            this.orderTagList = this.$fun.deepClone(order_detail_tag);
            this.$refs.editTag.value = order_detail_tag[0].id
          }, 500)
        } else {
          if (item.order_detail_tag != undefined) {
            item.order_detail_tag.forEach((items) => {
              items.list = list
            })
            this.orderTagList = this.$fun.deepClone(item.order_detail_tag);
          } else {
            item.porder_detail_tag.forEach((items) => {
              items.list = list
            })
            this.orderTagList = this.$fun.deepClone(item.porder_detail_tag);
          }
          setTimeout(() => {
            this.newFullscreenLoading = false;
          }, 500)
        }
      });
    },
    //保存商品的标签编号
    saveGoodsTag(msg) {
      this.newList.forEach((item) => {
        if (item.id === this.goodsInfo.id) {
          item.newTagTable = this.$fun.deepClone(this.oneLayerToTwoLayers(msg));
          if (item.order_detail_tag == undefined) {
            item.porder_detail_tag = msg
          } else {
            item.order_detail_tag = msg
          }
        }
      })
      this.orderTagList = [];
      this.selectTagDialogVisible = false;
    },
    //待报价订单或临时订单
    newOfferOrder(order_sn) {
      this.$api.storageDeliverDetail({porder_sn: order_sn}).then((res) => {
        this.datas = res.data;
        this.timer = new Date().getTime();
        this.fullscreenLoading = false;
        this.goodsPrice = res.data.porder_amount.commodity_amount_jpy;
        res.data.porder_detail.forEach((item) => {
          item.checked = false;
          this.submitNum += item.send_num;
          item.value = this.$fun.deepClone(item.send_num);
          let arr = [];
          item.porder_detail_tag.forEach((tagItem) => {
            let table = [];
            table.push({key: '类别', value: tagItem.type_translate});
            table.push({key: '标签号', value: tagItem.no});
            table.push({key: '商品码', value: tagItem.goods_no});
            table.push({key: 'type', value: tagItem.type});
            table.push({key: 'text_line_four', value: tagItem.text_line_four});
            table.push({key: 'text_line_one', value: tagItem.text_line_one});
            table.push({key: 'text_line_three', value: tagItem.text_line_three});
            table.push({key: 'text_line_two', value: tagItem.text_line_two});
            arr.push({table: table, id: item.porder_detail_id})
          })
          item.newTagTable = arr;
        })
        this.newList = res.data.porder_detail;
      });
    },
    //弹出从普通仓库追加商品
    showAddCommonWarehouseGoodsToPorderDialog() {
      this.idsCount = this.newList.length;
      let str = ''
      this.newList.forEach((item) => {
        str += item.order_detail_id + ','
      })
      str = str.substr(0, str.length - 1);
      this.timer = new Date().getTime();
      this.ids = str;
      this.addCartGoodsToOrderDialogVisible = true;
    },
    beforeAvatarUpload(file) {
      const isJPG = ["image/jpeg", "image/png", "image/jpg"].indexOf(file.type) != -1;
      if (!isJPG) {
        this.$message.error(this.$fanyi("请上传图片"));
        return isJPG;
      }
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi("写真上传中"),
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
    },
    // 订单预览
    newOrder() {
      let id = this.$fun.deepClone(this.$route.query.id);
      let idList = id.split(",");
      let newIdList = [];
      for (let i = 0; i < idList.length; i++) {
        newIdList.push({
          order_detail_id: idList[i]
        })
      }
      this.$api.porderSubmitPreview({stock: newIdList}).then((res) => {
        if (res.code != 0) {
          this.$confirm(
              res.msg,
              '注意',
              {
                confirmButtonText: this.$fanyi('确定'), //确定
                cancelButtonText: this.$fanyi('取消'), //取消
                type: "warning",
                center: true,
                dangerouslyUseHTMLString: true,
              }
          ).then(() => {
            window.close()
          }).catch(() => {
            window.close()
          });
          return
        }
        this.fullscreenLoading = false;
        this.datas = res.data;
        this.timer = new Date().getTime();
        res.data.porder_data.forEach((item) => {
          item.checked = false;
          item.value = this.$fun.deepClone(item.send_await_num);
          this.submitNum += item.value;
          let arr = [];
          item.order_detail_tag.forEach((tagItem) => {
            let table = [];
            table.push({key: '类别', value: tagItem.type_translate});
            table.push({key: '标签号', value: tagItem.no});
            table.push({key: '商品码', value: tagItem.goods_no});
            table.push({key: 'type', value: tagItem.type});
            arr.push({table: table, id: item.id})
          })
          item.newTagTable = arr;
        })
        this.newList = res.data.porder_data;
        this.commodityTotal()
      });
    },
    commodityTotal() {
      this.goodsPrice = 0;
      this.submitNum = 0;
      let price = 0;
      this.newList.forEach((item) => {
        price += (Number(item.value) * Number(item.confirm_price)) * Number(item.order_exchange_rate);
        price = Number(price.toFixed(0))
        this.submitNum += Number(item.value)
      })
      this.goodsPrice = price
    },
    //追加商品购物车的商品至订单
    cartAddGoodsToOrder(msg) {
      msg.forEach((item) => {
        item.offer_num_bak = item.confirm_num;
        item.order_detail_id = item.id;
        this.submitNum += item.value;
        let arr = [];
        item.order_detail_tag.forEach((tagItem) => {
          let table = [];
          table.push({key: '类别', value: tagItem.type_translate});
          table.push({key: '标签号', value: tagItem.no});
          table.push({key: '商品码', value: tagItem.goods_no});
          table.push({key: 'type', value: tagItem.type});
          arr.push({table: table, id: item.id})
        })
        item.newTagTable = arr;
        this.newList.push(item);
      })
      this.$forceUpdate();
      this.addCartGoodsToOrderDialogVisible = false;
      this.commodityTotal();
    },
    //图片上传成功
    handleAvatarSuccess(res, file) {
      this.newList.forEach((item) => {
        if (this.goodsInfo.id === item.id) {
          item.pic = res.data;
          this.loading.close();
        }
      })
    },
    // 全选
    allChoice() {
      if (this.checked) {
        this.newList.forEach((item) => {
          item.checked = true;
        });
      } else {
        this.newList.forEach((item) => {
          item.checked = false;
        });
      }
      this.$forceUpdate();
    },
    // 删除商品
    delOrder() {
      this.submitNum = 0;
      if (this.checked === true) {
        this.newList = [];
        this.checked = false;
        this.submitNum = 0;
      } else {
        for (let i = this.newList.length - 1; i >= 0; i--) {
          if (this.newList[i].checked == true) {
            this.newList.splice(i, 1);
          } else {
            this.submitNum += this.newList[i].value;
          }
        }
      }
    },
    // 是否全选中
    isCheckedAll() {
      var flag = this.newList.every((item) => item.checked == true);
      if (flag == true) {
        this.checked = true;
      } else {
        this.checked = false;
      }
      this.$forceUpdate();
    },
    //输入框取整
    numTrunc(e, item) {
      console.log(e)
      this.submitNum = 0;
      let value = Number(item.value);
      item.value = Math.trunc(value);
      if (item.value > item.send_await_num) {
        item.value = item.send_await_num;
      }
      this.newList.forEach((newListItem) => {
        if (newListItem.checked) {
          newListItem.value = e;
          if (newListItem.value > newListItem.send_await_num) {
            newListItem.value = newListItem.send_await_num;
          }
        }
      })
      this.commodityTotal();
    },
    // 单选
    radio(i) {
      this.checkedList = [];
      let flag = this.newList[i].checked == true;
      if (flag) {
        this.newList[i].checked = true;
      } else {
        this.newList[i].checked = false;
      }
      this.newList.forEach((item) => {
        if (item.checked) {
          this.checkedList.push(item)
        }
      })
      this.isCheckedAll();
      this.$forceUpdate();
    },
    //输入框校验
    newChannelInputLimit(e) {
      let key = e.key;
      // 不允许输入'e'、'.'、'。'、'-'
      if (key === "e" || key === "." || key === "。" || key == "-" || key == "-" || key == "-") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    handleClose(done) {
      this.$confirm(`${this.$fanyi("确认关闭")}？`).then(_ => {
        done();
      }).catch(_ => {

      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../../../css/mixin.scss";

.main.zhu {
  margin-top: 26px;
  margin-bottom: 30px;
  background: #ffffff;
  min-height: 482px;
  border-radius: 10px;
  // overflow: hidden;
  position: relative;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 73px;
    border-bottom: 1px solid #DFDFDF;
    padding: 0 26px;
    width: 1400px;

    span {
      font-size: 16px;
      font-weight: 400;
      font-family: "Open Sans", Sans-serif;
      color: #2f2f2f;
      line-height: 16px;

      .headT1 {
        display: block;
        color: #333333;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  .order {
    margin: 26px 26px 0;
    padding-bottom: 30px;

    .inputBox {
      width: 60px;
    }

    .orderNumber, .newOrderNumber {
      width: 120px;
    }

    .designation {
      width: 70px;
    }

    .mirror {
      width: 88px;
    }

    .handQuantity {
      width: 148px;

      .selectTagText {
        cursor: pointer;
        width: 148px;
        height: 87px;
        border: 1px solid #DFDFDF;
      }

      .tagSettingTableContainer {
        width: 148px;
        border: 1px solid #DFDFDF;

        .tagTableContainer {
          border-bottom: 1px solid #DFDFDF;
          width: 148px;
          height: 30px;
          font-size: 12px;

          div {
            width: 100%;
            height: 100%;
            line-height: 30px;
          }
        }
      }
    }

    .goods,
    .newGoods {
      width: 220px;

      div {
        padding: 30px 12px 0 13px;
        line-height: 20px;
        text-align: left;
      }
    }

    .newGoods {
      width: 260px;
    }

    .goodsSpecification,
    .newGoodsSpecification {
      width: 210px;
      min-width: 210px;

      .showDetail {
        height: 62px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        .showDetailCon {
          line-height: 20px;

          .goodsDetailOpt {
            width: 175px;
            text-align: left;
          }
        }
      }

      .purchaseData {
        line-height: 20px;
        font-size: 14px;

        div {
          text-align: left;
        }
      }
    }

    .newGoodsSpecification {
      .goodsDetailOpt {
        width: 210px !important;
      }
    }

    .deliveryQuantity {
      width: 140px;

      /deep/ .el-input__inner {
        width: 80px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 3px;
      }
    }

    .remark {
      width: 150px;

      /deep/ .el-textarea__inner {
        width: 110px;
        height: 100px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 3px;
        margin-left: 20px;
      }
    }

    .header {
      height: 56px;
      background: #F0F0F0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      z-index: 5;
      position: sticky;
      top: 58px;

      li {
        line-height: 56px;
        text-align: center;
        font-size: 14px;
        color: #333;
      }
    }

    .goodsBox,
    .newGoodsBox {
      overflow: hidden;

      .orderNumberContainer {
        height: 60px;
      }

      .goodsConUl {
        color: #333;
        font-size: 14px;
        border-bottom: 3px solid #DFDFDF;

        li {
          text-align: center;
          min-height: 120px;
        }
      }
    }

    .newGoodsBox {
      .goodsConUl:nth-child(3n) {
        border-bottom: 1px solid #DFDFDF;
      }

      .goodsConUl:last-child {
        border-bottom: 1px solid #B4272B;
      }
    }

    .dropDown {
      display: flex;
      justify-content: flex-end;
      margin-right: 50px;

      .addShow {
        width: 100px;
        height: 30px;
        background: #B4272B;
        border-radius: 0 0 6px 6px;
        color: #fff;
        justify-content: center;
        cursor: pointer;

        .addShowText {
          margin-left: 4px;
        }

        div {
          font-size: 14px;
        }
      }
    }

  }

  .showAllGoods {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 48px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.05);
    border-radius: 0 0 4px 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9;

    &.openBtn {
      position: fixed;
      bottom: 101px;
      width: 1400px;
      margin: 0 auto;
      left: 0;
      right: 0;
    }

    img {
      width: 24px;
      height: 24px;
      margin-right: 24px;
      cursor: pointer;
    }
  }
}

.isAmazonText {
  font-weight: 700;
  font-size: 12px;
  color: #70B603;
  margin-top: 10px;
}

.addCartGoodsToOrderContainer {
  /deep/ .el-dialog {
    border-radius: 10px;
    box-sizing: border-box;

    .el-dialog__header {
      padding: 30px 30px 0;

      .el-dialog__title {
        font-size: 18px;
        font-weight: bold;
        color: #333;
        padding-bottom: 7px;
        border-bottom: 4px solid #B4272B;
      }

      .el-dialog__headerbtn {
        top: 30px;
        font-size: 19px;

        .el-dialog__close {
          color: #B4272b;
        }
      }
    }

    .el-dialog__body {
      padding: 0;
    }

    .el-dialog__footer {
      padding: 0;

      button {
        border-radius: 8px;
        height: 34px;
        width: 120px;
        line-height: 34px;
        padding: 0;
        font-size: 14px;
      }

      button:first-child {
        color: #B4272B;
        border: 1px solid #B4272B;
        margin-right: 26px;
      }

      .el-button + .el-button {
        margin-left: 0;
      }
    }
  }
}

.operatingOptions {
  width: $pageWidth;
  height: 70px;
  position: sticky;
  margin: 20px auto 30px;
  right: 0;
  left: 0;
  z-index: 10;
  bottom: 0;
  border: 1px solid #e8e8e8;
  // height: 106px;
  background: #ffffff;
  box-shadow: 0 0 20px 0 rgba(10, 11, 34, 0.05);
  border-radius: 10px 10px 0 0;
  padding: 10px 26px 10px 0;
  justify-content: space-between;
  font-size: 16px;

  .fontWeightBold {
    margin: 0 8px;
  }

  .choies {
    margin-left: 26px;

    .margin-left-52 {
      margin-left: 52px;
    }

    .goodsNum {
      div:last-child {
        color: #B4272B;
        font-weight: bold;
      }
    }
  }

  .qx {
    margin: 0 25px 0 6px;
    font-size: 16px;
    color: #333;
  }

  .deleteText {
    color: #B4272B;
    cursor: pointer;
  }

  .AdditionalBtn {
    width: 80px;
    height: 34px;
    background: #B4272B;
    border-radius: 8px;
    cursor: pointer;
    line-height: 34px;
    text-align: center;
    font-size: 14px;
    color: #fff;
  }

  .buttonGroup {
    margin-left: -30px;

    button {
      width: 120px;
      height: 34px;
      border-radius: 8px;
      border: 1px solid #B4272B;
      font-size: 14px;
      font-weight: 400;
      color: #B4272B;
      background: white;
      line-height: 34px;
      padding: 0 20px;
      margin: 10px 10px;
    }

    button:last-child {
      color: #ffffff;
      background: #B4272B;
    }
  }

  .submitDeliveryOrderBtn {
    width: 120px;
    height: 34px;
    background: #B4272B;
    border-radius: 8px;
    line-height: 34px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
  }
}

.goodsAttributeEditContainer,
.goodsOptionListContainer {
  /deep/ .el-dialog {
    border-radius: 10px;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .el-dialog__header {
      padding: 0;

      .el-dialog__title {
        font-size: 18px;
        font-weight: bold;
        color: #333;
        padding-bottom: 7px;
        border-bottom: 4px solid #B4272B;
      }

      .el-dialog__headerbtn {
        top: 30px;
        font-size: 19px;

        .el-dialog__close {
          color: #B4272b;
        }
      }
    }

    .el-dialog__body {
      padding: 0;
    }

    .el-dialog__footer {
      padding: 0;

      button {
        border-radius: 8px;
        height: 34px;
        width: 120px;
        line-height: 34px;
        padding: 0;
        font-size: 14px;
      }

      button:first-child {
        color: #B4272B;
        border: 1px solid #B4272B;
        margin-right: 26px;
      }

      .el-button + .el-button {
        margin-left: 0;
      }
    }
  }

  .goodsAttributeEditTypeContainer {
    margin-top: 36px;

    .el-button + .el-button {
      margin-left: 26px;
    }
  }

  .goodsDetailContainer {
    .keyContainer {
      margin-top: 20px;
      min-width: 80px;
    }

    .valueAttributeListContainer {
      display: flex;
      flex-wrap: wrap;

      div {
        padding: 8px 16px;
        border-radius: 3px;
        box-sizing: border-box;
        border: 1px solid #DFDFDF;
        margin: 20px 0 0 20px;
        cursor: pointer;
      }

      .active {
        border: 1px solid #B4272B;
        color: #B4272B;
      }
    }
  }

  .artificialAttributeTitle {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background: #F0F0F0;
    margin-top: 20px;
    margin-right: 26px;

    div {
      width: 215px;
      height: 30px;
      padding-left: 8px;
      line-height: 30px;
    }
  }

  .artificialAttributeEditContainer {
    border-bottom: 1px solid #DFDFDF;

    .artificialAttributeEditLeftContainer {
      padding: 10px 8px;
    }
  }

  .addAttributeText {
    margin-top: 12px;
    text-decoration: underline;
    color: #50C136;
    cursor: pointer;
  }
}


input[type="checkbox"]:checked {
  background: #4A91E9;
  border: none;
}
</style>
