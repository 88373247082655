<template>
  <div element-loading-text="" element-loading-background="rgba(0, 0, 0, 0.3)" class="orderPage"
       v-loading.fullscreen.lock="fullscreenLoading">
    <Dialog ref="dialog" :config="config"></Dialog>
    <div class="main zhu base-w">
      <!-- 头部 -->
      <div class="head">
        <span class="flexAndCenter">
          <font class="headT1">{{ $fanyi('配送单号') }}：</font>
          <span class="headT1">{{ datas.porder_sn }}</span>
        </span>
        <div class="fontSize14 colorB4272B">
          注意：出荷調整が必要の場合は担当者までご連絡ください！
        </div>
      </div>
      <!-- 订单列表 -->
      <div class="order">
        <ul class="header flex">
          <li class="inputBox">No</li>
          <li class="orderNumber">{{ $fanyi('订单号') }}</li>
          <li class="inputBox">{{ $fanyi('番号') }}</li>
          <li class="goods">{{ $fanyi('产品') }}</li>
          <li class="goodsSpecification">{{ $fanyi('商品详细') }}</li>
          <li class="totalPurchaseData">
            <span>{{ $fanyi('购入数据') }}</span>
          </li>
          <li class="tagSetting">{{ $fanyi('标签信息') }}</li>
          <li class="deliveryQuantity">{{ $fanyi('提货数量') }}</li>
          <li class="remark" style="line-height: 56px !important">
            {{ $fanyi('单番装箱备注') }}
          </li>
          <li class="remark" style="line-height: 56px !important">
            {{ $fanyi('スタッフ') }}
          </li>
          <li class="boxMessage" style="line-height: 56px !important">
            {{ $fanyi('所在箱子') }}
          </li>
        </ul>
        <div class="addShow">
          <ul v-for="(item, itemIndex) in newList" :key="item.id" class="goodsConUl flexAndCenter">
            <li class="inputBox flexAndCenterAndCenter cursorPointer" style="flex-direction: column"
                @click="getEditSnInfo(item)">
              <el-popover placement="right" title="合计数量" trigger="focus" width="200">
                <statistics :checkedList="checkedList"/>
                <el-checkbox slot="reference"
                             v-model="item.checked"
                             style="margin:0"
                             type="checkbox"
                             @change="radio(itemIndex)"
                />
              </el-popover>
              {{ itemIndex + 1 }}
              <div v-if="item.id!=snInfo.id" :title="item.for_sn" class="u-line" style="width: 40px">
                {{ item.for_sn }}
              </div>
              <el-input v-else v-model="item.for_sn" :placeholder="$fanyi('管理番号')" size="mini"
                        @blur="updateForSn(item)"
                        @input="$forceUpdate()"/>
            </li>
            <li class="orderNumber flexAndCenterAndCenter cursorPointer">
              <router-link target="_blank" class="colorB4272B cursorPointer" style="text-decoration: underline" :to="{
                path: '/OrderDetails',
                query: { order_sn: item.order_sn },
              }">
                {{ item.order_sn }}
              </router-link>
            </li>
            <li class="inputBox flexAndCenterAndCenter">{{ item.sorting }}</li>
            <li class="goods flexAndCenterAndCenter">
              <div class="flexAndCenterAndCenter">
                <el-popover placement="right" trigger="hover">
                  <img :src="item.pic" style="width: 300px; height: 300px"/>
                  <router-link slot="reference" target="_blank" :to="{
                    path: '/ProductDetails',
                    query: {
                      goods_id: item.goods_id,
                      fromPlatform: item.from_platform,
                    },
                  }">
                    <img :src="item.pic" style="width: 60px; height: 60px; margin-top: 30px"/>
                  </router-link>
                </el-popover>
                <router-link target="_blank" class="goods_title" :to="{
                  path: '/ProductDetails',
                  query: {
                    goods_id: item.goods_id,
                    fromPlatform: item.from_platform,
                  },
                }">
                </router-link>
              </div>
            </li>
            <li class="goodsSpecification flexAndCenterAndCenter">
              <div class="showDetail">
                <el-popover trigger="hover" placement="top">
                  <div class="showDetailCon u-line">
                    <p v-for="(bitem, bindex) in item.detail" :key="bitem.key + bindex"
                       :title="bitem.key + ':' + bitem.value" class="goodsDetailOpt ispay">
                      {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                    </p>
                  </div>
                  <div class="showDetailCon u-line" slot="reference">
                    <p v-for="(bitem, bindex) in item.detail" :key="bitem.key + bindex"
                       :title="bitem.key + ':' + bitem.value" class="goodsDetailOpt ispay u-line">
                      {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                    </p>
                  </div>
                </el-popover>
              </div>
            </li>
            <li class="totalPurchaseData flexAndCenterAndCenter">
              <div class="purchaseData">
                <div>{{ $fanyi('数量') }}：{{ item.offer_num_bak }}</div>
                <div>
                  {{ $fanyi('单价') }}：{{
                    item.confirm_price
                  }}{{ $fanyi('元') }}
                  （{{
                    $fun.yenNumSegmentation(Number(Number(item.confirm_price) * Number(exchangeRate)).toFixed(0))
                  }}円）
                </div>
                <div>
                  {{ $fanyi('国内运费') }}：{{
                    Number(item.confirm_freight).toFixed(2)
                  }}{{ $fanyi('元') }}
                  （{{
                    $fun.yenNumSegmentation(Number(Number(item.confirm_freight) * Number(exchangeRate)).toFixed(0))
                  }}円）
                </div>
                <div>
                  {{ $fanyi('小计') }}：
                  {{ item.rmbPriceTotal }}
                  {{ $fanyi('元') }}
                  （{{
                    $fun.yenNumSegmentation(item.yenPriceTotal)
                  }}円）
                </div>
              </div>
            </li>
            <li class="tagSetting flexAndCenterAndCenter" style="flex-direction: column; padding: 15px 0">
              <div class="tagSettingTableContainer" :style="tagIndex === item.newTagTable.length - 1
                ? ''
                : 'margin-bottom: 10px'
                " v-for="(tagItem, tagIndex) in item.newTagTable" :key="tagIndex" style="border-bottom: none">
                <div class="tagTableContainer flexAndCenter" v-for="(newTagItem, newTagIndex) in tagItem.table"
                     :key="newTagIndex">
                  <div v-if="item.key !== 'type'" :title="newTagItem.value" class="u-line" style="width: 140px">
                    {{ newTagItem.value }}
                  </div>
                </div>
              </div>
            </li>
            <li class="deliveryQuantity flexAndCenterAndCenter">
              {{ item.send_num }}
            </li>
            <li class="remark flexAndCenterAndCenter">
              <el-popover v-if="item.client_remark!=''" placement="top" trigger="hover" width="300">
                <div>{{ item.client_remark }}</div>
                <div slot="reference" class="u-line-3 cursorPointer" style="width: 120px">
                  {{ item.client_remark }}
                </div>
              </el-popover>
            </li>
            <li class="remark flexAndCenterAndCenter">
              <el-popover v-if="item.y_reply!=''" placement="top" trigger="hover" width="300">
                <div>{{ item.y_reply }}</div>
                <div slot="reference" class="u-line-3 cursorPointer" style="width: 120px">
                  {{ item.y_reply }}
                </div>
              </el-popover>
            </li>
            <li class="boxMessage flexAndCenterAndCenter">
              <el-popover placement="bottom" trigger="hover">
                <div>
                  <div v-for="(boxItem, boxIndex) in item.in_porder_box" :key="boxIndex">
                    {{ $fanyi('箱号') }}{{ boxItem.box_number }}：{{
                      boxItem.in_box_num
                    }}{{ $fanyi('个') }}
                  </div>
                </div>
                <div slot="reference" class="boxMessageContainer cursorPointer">
                  <div v-for="(boxItem, boxIndex) in item.in_porder_box" :key="boxIndex">
                    {{ $fanyi('箱号') }}{{ boxItem.box_number }}：{{
                      boxItem.in_box_num
                    }}{{ $fanyi('个') }}
                  </div>
                </div>
              </el-popover>
            </li>
          </ul>
        </div>
        <boxMessage :logisticsGroupTable="datas.logistics_group" :table="datas.porder_box" style="margin-top: 52px"/>
        <addressInfo v-if="timer != null" :key="timer" ref="addressInfo" :status="orderStatusName"
                     :deliveryDetails="datas" style="margin-top: 52px"/>
      </div>
    </div>
    <!--    操作栏-->
    <div class="operatingOptions flexAndCenter">
      <div class="flexAndCenter">
        <input id="" v-model="checked" name="" style="margin-left: 20px" type="checkbox" @change="allChoice"/>
        <button class="qx" @click="checked = !checked; allChoice();">
          {{ $fanyi("全选") }}
        </button>
        <div v-if="datas.amazon_authorization==true" class="AdditionalBtn"
             style="width: 120px;"
             @click="markerAmazon">
          亚马逊入仓商品
        </div>
      </div>
      <div :class="datas.amazon_authorization==true?'':'flexAndCenter'" class="choies">
        <div class="goodsNum flexAndCenter">
          <div>{{ $fanyi('提出番数') }}：</div>
          <div>{{ newList.length }}</div>
        </div>
        <div :class="datas.amazon_authorization==true?'':'margin-left-52'" class="goodsNum flexAndCenter">
          <div>{{ $fanyi('提出数量') }}：</div>
          <div>{{ submitNum }}</div>
        </div>
      </div>
      <div class="buttonGroup flex">
        <div class="margin-top-21 margin-right-21">
          <div class="margin-bottom-10">
            （1{{ $fanyi('元') }}={{
              datas.porder_status === 30 ? exchangeRate : datas.exchange_rate
            }}{{ $fanyi('円') }}）
          </div>
          <div>
            合計商品代金：{{
              $fun.yenNumSegmentation(datas.porder_amount.commodity_amount_jpy)
            }}&nbsp;円
          </div>
        </div>
        <div class="margin-top-21 margin-right-50">
          <div class="flexAndCenter margin-bottom-10">
            {{ $fanyi('国际运费') }}：
            <div class="colorB4272B fontWeightBold fontSize16">
              {{
                $fun.yenNumSegmentation(
                    pOrderPaymentInfo.international_logistics_fee_jpy
                )
              }}
            </div>
            {{ $fanyi('円') }}
          </div>
          <div class="flexAndCenter">
            {{ $fanyi('其他费用') }}：
            <div class="colorB4272B fontWeightBold fontSize16">
              {{ pOrderPaymentInfo.other_fee_jpy }}
            </div>
            {{ $fanyi('円') }}
            <el-popover placement="bottom" trigger="hover">
              <div class="otherAmountDetailContainer">
                <div class="otherPriceTitle">{{ $fanyi('其他费用明细') }}</div>
                <div class="otherExpensesHead">
                  <ul class="flexAndCenter color333 fontSize14">
                    <li class="optionSelect">{{ $fanyi('名义') }}</li>
                    <li class="num">{{ $fanyi('费用（元）') }}</li>
                  </ul>
                </div>
                <!-- 单个商品 -->
                <div class="otherExpensesFooter">
                  <ul v-for="(detailItem, index) in otherAmountDetailList" :key="index" class="flexAndCenter color333"
                      style="background: transparent">
                    <li class="optionSelect">
                      {{ detailItem.desc }}
                    </li>
                    <li class="num">
                      {{ detailItem.fee }}
                    </li>
                  </ul>
                </div>
                <div class="footer">
                  <div>合計</div>
                  <div>{{ otherAmountPrice }}</div>
                </div>
              </div>
              <div slot="reference" class="detailsText">
                {{ $fanyi('详细') }}
              </div>
            </el-popover>
          </div>
        </div>
        <div class="margin-top-21 margin-right-21">
          <div class="flexAndCenter margin-bottom-10">
            {{ $fanyi('合计') }}：
            <div class="colorB4272B fontWeightBold fontSize16">
              {{ $fun.yenNumSegmentation(pOrderPaymentInfo.sum_amount_jpy) }}
            </div>
            {{ $fanyi('円') }}
          </div>
          <div class="flexAndCenter margin-bottom-10" v-show="discounts_id !== ''">
            {{ $fanyi('抵扣金额') }}：
            <div class="colorB4272B fontWeightBold fontSize16">
              -{{
                $fun.yenNumSegmentation(pOrderPaymentInfo.discounts_amount_jpy)
              }}
            </div>
            {{ $fanyi('円') }}
          </div>
          <div class="flexAndCenter">
            {{ $fanyi('应付') }}：
            <div class="colorB4272B fontWeightBold fontSize16">
              {{ $fun.yenNumSegmentation(pOrderPaymentInfo.pay_amount_jpy) }}
            </div>
            {{ $fanyi('円') }}
          </div>
        </div>
        <div class="margin-top-21 margin-right-50">
          <div class="flexAndCenter margin-bottom-10" v-show="datas.porder_status === 30 && couponList.length > 0">
            <div class="couponCheckedContainer flexAndCenter">
              <el-popover placement="top" popper-class="couponCheckedPopoverContainer" width="460" trigger="hover">
                <div class="couponMessageContainer">
                  <div class="couponListContainer">
                    <div v-for="(item, index) in couponList" :key="index" :class="item.type_name === '折扣券'
                      ? 'newCouponContainer'
                      : 'couponContainer'
                      " @click="confirmCheckedCoupon(index, item.id)">
                      <div>
                        <div class="couponName">
                          <div v-if="item.type_name === '折扣券' &&
                            item.discounts_amount_jpy == '0'
                            ">
                            {{ 100 - item.discounts_rate }}%割引
                          </div>
                          <div v-else>{{ item.discounts_amount_jpy }}円</div>
                          <div :style="item.type_name === '折扣券'
                            ? 'background: #2748B5;'
                            : ''
                            ">
                            配送依頼書
                          </div>
                          <div v-if="item.type_name === '折扣券'" :title="'NO.' + item.sn" class="newCouponNo">
                            NO.{{ item.sn }}
                          </div>
                        </div>
                        <div v-if="item.type_name !== '折扣券'" class="couponNo">
                          NO.{{ item.sn }}
                        </div>
                        <div v-else class="couponNo">
                          <span :title="item.supportLogisticsName" class="u-line-2 logisticsName">{{
                              item.supportLogisticsName
                            }}</span>&nbsp;&nbsp; 課金重量≥{{
                            Number(item.min_weight)
                          }}kg&nbsp;&nbsp; 金額≥{{
                            item.min_consumption_jpy
                          }}
                          円
                        </div>
                        <div class="date">
                          有効期限&nbsp;&nbsp;&nbsp;{{
                            item.effective_date.substring(0, 10)
                          }}
                          - {{ item.expiry_date.substring(0, 10) }}
                        </div>
                      </div>
                      <div v-if="item.type_name !== '折扣券'" class="couponType">
                        <div>金</div>
                        <div>券</div>
                      </div>
                      <div v-else class="couponType" style="color: #2748b5">
                        <div>折</div>
                        <div>扣</div>
                        <div>券</div>
                      </div>
                      <div v-show="checkedCouponIndex === index" class="checkedCouponContainer">
                        √
                      </div>
                    </div>
                  </div>
                </div>
                <el-checkbox slot="reference" v-model="couponChecked" @change="couponCheckedChange">
                  {{ $fanyi('使用优惠券') }}
                </el-checkbox>
              </el-popover>
            </div>
          </div>
          <div class="flexAndCenter" style="margin-left: 23px" v-show="discounts_id !== ''">
            {{ $fanyi('已使用') }}
            <div class="colorB4272B" style="margin-left: 8px">
              {{
                $fun.yenNumSegmentation(pOrderPaymentInfo.discounts_amount_jpy)
              }}{{ $fanyi('円金券') }}
            </div>
          </div>
        </div>
        <button v-show="datas.porder_status === 30 && datas.p_porder_sn === ''" @click="paymentPageJump">
          {{ $fanyi('立即付款') }}
        </button>
        <button v-show="datas.porder_status === 60" @click="orderSignFor">
          {{ $fanyi('签收') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import boxMessage from './boxMessage'
import addressInfo from './addressInfo'
import Dialog from '../../../../../../../components/public/Dialog.vue'
import statistics from "@/views/UserCenter/views/userOrder/views/components/statistics.vue";

export default {
  components: {
    statistics,
    boxMessage,
    addressInfo,
    Dialog,
  },
  props: {
    orderStatusName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      datas: {
        porder_box: [],
        logistics_group: [],
        porder_amount: {
          commodity_amount_jpy: 0,
        },
        address: {
          importer: {},
          receiver: {},
        },
      },
      checkedList: [],
      checked: false,
      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('提示'),
        txtcenter: 'center',
        btnTxt: [this.$fanyi('确认'), this.$fanyi('取消')],
      },
      show: true,
      newList: [],
      snInfo: {},
      couponId: null,
      couponChecked: false,
      checkedCouponIndex: null,
      timer: null,
      fullscreenLoading: true,
      tagIndex: 0,
      submitNum: 0,
      discounts_id: '',
      couponList: [],
      otherAmountDetailList: [],
      otherAmountPrice: 0,
      pOrderPaymentInfo: {
        sum_amount_jpy: 0,
        pay_amount_jpy: 0,
        other_fee_jpy: 0,
        international_logistics_fee_jpy: 0,
      },
    }
  },
  computed: {
    ...mapState({
      orderFileList: 'fileList',
    }) /* 推荐 */,
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate')
      return Number(exchangeRate)
    },
  },
  created() {
    if (location.host.indexOf('.co.jp') !== -1 || location.host.indexOf('.com') !== -1) {
      this.show = false;
    }
    this.newOfferOrder(this.$route.query.pOrder_sn)
  },
  methods: {
    //待报价订单
    newOfferOrder(order_sn) {
      this.$api.storageDeliverDetail({porder_sn: order_sn}).then((res) => {
        let volume_weight = 0
        this.timer = new Date().getTime()
        this.$forceUpdate()
        if (res.data.porder_status === 30 && res.data.p_porder_sn === '') {
          this.getStoragePOrderPayment()
        } else {
          this.pOrderPaymentInfo = res.data.porder_amount
        }
        if (res.data.porder_status === 30 && res.data.porder_amount.other_fee_jpy > 0) {
          this.$confirm(
              '追加費用が発生しております。\n' +
              '詳しくは「その他」の詳細ボタンをクリックしてからご確認下さい。',
              '注意',
              {
                confirmButtonText: this.$fanyi('确定'), //确定
                type: "warning",
                center: true,
                showCancelButton: false,
                closeOnClickModal: false,
                showClose: false,
              }
          ).then(() => {

          }).catch(() => {

          });
        }
        this.fullscreenLoading = false;
        res.data.porder_box.forEach((item) => {
          volume_weight += item.volume_weight
          item.activities = []
          item.other_amount_detail.forEach((detailItem) => {
            this.otherAmountPrice += Number(detailItem.fee)
            this.otherAmountDetailList.push(detailItem)
          })
        })
        res.data.porder_detail.forEach((item) => {
          item.checked = false;
          this.submitNum += item.send_num;
          item.value = this.$fun.deepClone(item.send_num);
          let arr = [];
          item.rmbPriceTotal = (Number(item.confirm_num) * Number(item.confirm_price) + Number(item.confirm_freight)).toFixed(2);
          item.yenPriceTotal = (Number(item.rmbPriceTotal) * Number(this.datas.porder_status === 30 ? this.exchangeRate : res.data.exchange_rate)).toFixed(0);
          item.porder_detail_tag.forEach((tagItem) => {
            let table = []
            table.push({key: '类别', value: tagItem.type_translate})
            table.push({key: '标签号', value: tagItem.no})
            table.push({key: '商品码', value: tagItem.goods_no})
            arr.push({table: table})
          })
          item.newTagTable = arr
        })
        this.newList = res.data.porder_detail
        this.datas = res.data
        if (this.datas.porder_status === 30 && this.datas.p_porder_sn === '') {
          this.$api.usableDiscount({
            page: 1,
            pageSize: 999,
            porder_sn: this.$route.query.pOrder_sn,
          })
          .then((res) => {
            res.data.data.forEach((item) => {
              if (item.type_name === '折扣券') {
                item.supportLogisticsName = ''
                item.logistics_group.forEach((logistics_group_item, index) => {
                      if (index === item.logistics_group.length - 1) {
                        item.supportLogisticsName += logistics_group_item.logistics_name
                      } else {
                        item.supportLogisticsName += logistics_group_item.logistics_name + ','
                      }
                    }
                )
                this.couponList.push(item)
              } else {
                if (this.datas.porder_amount.pay_amount_jpy > Number(item.min_consumption_jpy)) {
                  this.couponList.push(item)
                }
              }
            })
          })
        }
      })
    },
    //支付页面跳转
    paymentPageJump() {
      let url = `/payment?pOrder_sn=${this.$route.query.pOrder_sn}&discounts_id=${this.discounts_id}`
      window.open(url, '_blank')
    },
    //获取配送单支付数据
    getStoragePOrderPayment() {
      this.$api.storagePOrderPayment({
        porder_sn: this.$route.query.pOrder_sn,
        discounts_id: this.discounts_id,
      }).then((res) => {
        this.pOrderPaymentInfo = res.data
      })
    },
    //选中优惠券
    confirmCheckedCoupon(index, id) {
      this.checkedCouponIndex = index
      this.discounts_id = id
      this.couponChecked = true
      this.getStoragePOrderPayment()
    },
    // 全选
    allChoice() {
      if (this.checked) {
        this.newList.forEach((item) => {
          item.checked = true;
        });
      } else {
        this.newList.forEach((item) => {
          item.checked = false;
        });
      }
      this.$forceUpdate();
    },
    // 单选
    radio(i) {
      this.checkedList = [];
      let flag = this.newList[i].checked == true;
      if (flag) {
        this.newList[i].checked = true;
      } else {
        this.newList[i].checked = false;
      }
      this.newList.forEach((item) => {
        if (item.checked) {
          this.checkedList.push(item)
        }
      })
      this.isCheckedAll();
      this.$forceUpdate();
    },
    //更新使用优惠券的状态
    couponCheckedChange() {
      if (this.couponChecked === false && this.discounts_id !== '') {
        this.discounts_id = ''
        this.checkedCouponIndex = null
        this.getStoragePOrderPayment()
      }
      if (this.discounts_id === '' && this.couponChecked === true) {
        this.couponChecked = false
        this.$forceUpdate()
      }
    },
    //获取要编辑的管理番号详情
    getEditSnInfo(val) {
      this.snInfo = val;
    },
    updateForSn(val) {
      if (val.for_sn != '') {
        this.$api.updateOrderDetailForSn({order_detail_id: val.id, for_sn: val.for_sn}).then((res) => {

        });
      }
    },
    markerAmazon() {
      let count = 0;
      let arr = [];
      this.newList.forEach((item) => {
        if (item.checked == true) {
          count++
          if (item.warehousing == undefined) {
            item.warehousing = true
          } else {
            item.warehousing = !item.warehousing
          }
          this.$forceUpdate();
          arr.push({
            order_sn: item.order_sn,
            order_detail_id: [item.id]
          })
        }
      })
      if (count == 0) {
        this.$message.error(this.$fanyi('请至少选择一个商品'))
        return
      }
      arr.forEach((item) => {
        this.$api.markersWarehouseGoods({
          order_sn: item.order_sn,
          order_detail_id: item.order_detail_id
        }).then((res) => {
        })
      })
    },
    //配送单签收
    orderSignFor() {
      this.$refs.dialog.open(
          this.$fanyi('是否签收该配送单？'),
          () => {
            this.$api
            .internationalLogisticsClientReceive({
              porder_sn: this.$route.query.pOrder_sn,
            })
            .then((res) => {
              if (res.code != 0) return this.$message.error(res.msg)
              this.$message.success(this.$fanyi('签收成功'))
            })
          },
          () => {
            this.$message(this.$fanyi('已取消签收'))
          }
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../../../../css/mixin.scss';

.main.zhu {
  margin-top: 26px;
  margin-bottom: 30px;
  background: #ffffff;
  min-height: 482px;
  border-radius: 10px;
  // overflow: hidden;
  position: relative;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 73px;
    border-bottom: 1px solid #dfdfdf;
    padding: 0 26px;
    width: 1400px;

    span {
      font-size: 16px;
      font-weight: 400;
      font-family: 'Open Sans', Sans-serif;
      color: #2f2f2f;
      line-height: 16px;

      .headT1 {
        display: block;
        color: #333333;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  .order {
    margin: 26px 26px 0;
    padding-bottom: 30px;

    .inputBox {
      width: 50px;
    }

    .orderNumber {
      width: 150px;

      div {
        padding: 0 14px;
        line-height: 20px;
        text-align: center;
      }
    }

    .newOrderNumber {
      width: 220px;
    }

    .designation {
      width: 70px;
    }

    .mirror {
      width: 88px;
    }

    .handQuantity {
      width: 90px;
    }

    .goods {
      width: 100px;

      .goods_title {
        padding: 0 6px 0 9px;
        line-height: 20px;
        text-align: left;
      }

      /deep/ .el-popover__reference-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .goodsSpecification {
      width: 160px;

      .showDetail {
        height: 62px;
        overflow: hidden;

        .showDetailCon {
          width: 160px;
          line-height: 20px;

          .goodsDetailOpt {
            width: 140px;
            text-align: left;
          }
        }
      }
    }

    .totalPurchaseData {
      width: 220px;

      .purchaseData {
        line-height: 20px;
        font-size: 14px;

        div {
          text-align: left;
        }
      }
    }

    .tagSetting {
      width: 148px;

      .tagSettingTableContainer {
        width: 140px;
        border: 1px solid #dfdfdf;

        .tagTableContainer {
          border-bottom: 1px solid #dfdfdf;
          width: 140px;
          height: 30px;
          font-size: 12px;

          div {
            width: 100%;
            height: 100%;
            line-height: 30px;
          }
        }
      }
    }

    .deliveryQuantity {
      width: 120px;

      /deep/ .el-input__inner {
        width: 100px;
        height: 30px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        border-radius: 3px;
      }
    }

    .remark {
      width: 120px;

      /deep/ .el-textarea {
        display: flex;
        align-items: center;
        justify-content: center;

        .el-textarea__inner {
          width: 100px;
          height: 100px;
          background: #ffffff;
          border: 1px solid #dfdfdf;
          border-radius: 3px;
        }
      }
    }

    .boxMessage {
      width: 170px;

      .boxMessageContainer {
        width: 150px;
        height: 100px;
        border: 1px solid #dfdfdf;
        border-radius: 3px;
        padding: 3px 9px;
        font-size: 14px;
        overflow: hidden;

        div {
          text-align: left;
        }
      }
    }

    .header {
      height: 56px;
      background: #f0f0f0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      z-index: 5;
      position: sticky;
      top: 58px;

      li {
        line-height: 56px;
        text-align: center;
        font-size: 14px;
        color: #333;
      }
    }

    .goodsBox,
    .newGoodsBox {
      overflow: hidden;

      .orderNumberContainer {
        height: 60px;
      }

      .goodsConUl {
        color: #333;
        font-size: 14px;
        border-bottom: 3px solid #dfdfdf;

        li {
          min-height: 120px;
          text-align: center;
          height: auto;
        }
      }
    }

    .newGoodsBox {
      .goodsConUl:nth-child(3n) {
        border-bottom: 1px solid #dfdfdf;
      }

      .goodsConUl:last-child {
        border-bottom: 1px solid #b4272b;
      }
    }

    .dropDown {
      display: flex;
      justify-content: flex-end;
      margin-right: 50px;

      .addShow {
        width: 100px;
        height: 30px;
        background: #b4272b;
        border-radius: 0 0 6px 6px;
        color: #fff;
        justify-content: center;
        cursor: pointer;

        .addShowText {
          margin-left: 4px;
        }

        div {
          font-size: 14px;
        }
      }
    }
  }

  .showAllGoods {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 48px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.05);
    border-radius: 0 0 4px 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9;

    &.openBtn {
      position: fixed;
      bottom: 101px;
      width: 1400px;
      margin: 0 auto;
      left: 0;
      right: 0;
    }

    img {
      width: 24px;
      height: 24px;
      margin-right: 24px;
      cursor: pointer;
    }
  }
}

.isAmazonText {
  font-weight: 700;
  font-size: 12px;
  color: #70B603;
  margin: 10px 0;
}

.otherAmountDetailContainer {
  color: #333333;

  .otherExpensesHead {
    ul {
      height: 40px;
      background: #f0f0f0;
      margin-top: 20px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      li {
        line-height: 40px;
        font-size: 14px;
        color: #333;
      }

      li:first-child {
        width: 200px;
        padding-left: 30px;
      }

      li:last-child {
        width: 120px;
        text-align: center;
      }
    }
  }

  .otherExpensesFooter {
    max-height: 400px;
    overflow-y: scroll;
    border-bottom: 1px solid #dfdfdf;

    ul {
      height: 50px;

      li {
        line-height: 50px;
        font-size: 14px;
        color: #333;
      }

      li:first-child {
        width: 200px;
        min-width: 200px;
        padding-left: 30px;
      }

      li:last-child {
        width: 120px;
        min-width: 120px;
        text-align: center;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    height: 48px;
    font-size: 14px;
    width: 100%;

    div:first-child {
      width: 200px;
      min-width: 200px;
      padding-left: 30px;
      font-weight: bold;
    }

    div:last-child {
      width: 120px;
      min-width: 120px;
      color: #b4272b;
      font-weight: bold;
      text-align: center;
    }
  }

  .otherPriceTitle {
    margin-bottom: 13px;
    font-size: 14px;
  }
}

.operatingOptions {
  width: $pageWidth;
  height: 120px;
  position: sticky;
  margin: 20px auto 30px;
  right: 0;
  left: 0;
  z-index: 10;
  bottom: 0;
  border: 1px solid #e8e8e8;
  // height: 106px;
  background: #ffffff;
  box-shadow: 0 0 20px 0 rgba(10, 11, 34, 0.05);
  border-radius: 10px 10px 0 0;
  padding-right: 26px;
  justify-content: space-between;
  font-size: 16px;

  .fontWeightBold {
    margin-right: 4px;
  }

  .choies {
    margin-left: 26px;

    .margin-left-52 {
      margin-left: 52px;
    }

    .goodsNum {
      div:last-child {
        color: #b4272b;
        font-weight: bold;
      }
    }
  }

  .qx {
    margin: 0 25px 0 6px;
    font-size: 16px;
    color: #333;
  }

  .deleteText {
    color: #b4272b;
    margin-right: 26px;
    cursor: pointer;
  }

  .AdditionalBtn {
    width: 80px;
    height: 34px;
    background: #b4272b;
    border-radius: 8px;
    cursor: pointer;
    line-height: 34px;
    text-align: center;
    font-size: 14px;
    color: #fff;
  }

  .buttonGroup {
    height: 100%;

    .margin-top-21 {
      margin-top: 21px;
    }

    .margin-bottom-10 {
      margin-bottom: 10px;
    }

    .margin-right-80 {
      margin-right: 80px;
    }

    .margin-right-21 {
      margin-right: 21px;
    }

    .margin-right-50 {
      margin-right: 50px;
    }

    .fontSize16 {
      font-size: 16px;
    }

    .detailsText {
      color: #b4272b;
      text-decoration: underline;
      margin-left: 9px;
      cursor: pointer;
    }

    button {
      width: 120px;
      height: 34px;
      border-radius: 8px;
      border: 1px solid #b4272b;
      font-size: 14px;
      font-weight: 400;
      color: white;
      background: #b4272b;
      line-height: 34px;
      margin-top: 43px;
    }
  }
}

.couponMessageContainer {
  height: 400px;
  overflow: scroll;

  .newCouponContainer {
    width: 420px !important;
    height: 150px !important;
    background: url('../../../../../../../assets/logisticsDiscountTicketColumnBg.png') no-repeat !important;
    background-size: 100% 100% !important;
    padding: 10px 0 0 25px !important;
  }

  .couponContainer,
  .newCouponContainer {
    width: 420px;
    height: 150px;
    margin-bottom: 6px;
    cursor: pointer;
    background: url('../../../../../../../assets/voucherColumnBg.png') no-repeat;
    background-size: 100% 100%;
    padding: 22px 0 0 25px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .couponName {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      div:first-child {
        color: #fff;
        font-weight: bold;
        font-size: 24px;
        margin-right: 11px;
      }

      div:nth-child(2) {
        width: 76px;
        height: 24px;
        background: #b5272d;
        border-radius: 6px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        font-size: 12px;
      }
    }

    .couponNo {
      color: #fff;
      font-size: 12px;
      display: flex;
      align-items: center;

      .logisticsName {
        width: 90px;
        cursor: pointer;
      }
    }

    .newCouponNo {
      color: #fff;
      font-size: 12px;
      width: 135px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-left: 5px;
      cursor: pointer;
    }

    .checkedCouponContainer {
      width: 20px;
      height: 20px;
      background: #b4272b;
      text-align: center;
      line-height: 20px;
      color: #fff;
      font-size: 12px;
      position: absolute;
      right: 0;
      bottom: 5px;
      border-bottom-right-radius: 5px;
    }

    .date {
      margin-top: 26px;
      color: #fff;
      font-size: 14px;
    }

    .couponType {
      color: #b5272d;
      font-weight: bold;
      font-size: 22px;
      margin-right: 18px;
      line-height: 24px;
    }

    .couponContentContainer {
      width: 380px;
      height: 150px;
      background: #ffffff;
      border: 1px solid #d2494d;
      padding: 10px 0 0 6px;
      box-sizing: border-box;
      position: relative;

      .couponName {
        color: #000000;
        font-weight: bold;
        width: 190px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 4px;
      }

      .couponUsageCounter {
        margin-bottom: 4px;

        div:first-child {
          color: #808080;
          font-size: 9px;
          margin-top: 3px;
        }

        div:last-child {
          margin-left: 3px;
          color: #b4272b;
          font-weight: bold;
          font-size: 14px;
        }
      }

      .date {
        color: #808080;
        font-size: 9px;
        text-align: center;
      }
    }
  }
}

.couponMessageContainer::-webkit-scrollbar {
  display: none !important;
}

.couponCheckedContainer {
  /deep/ .el-checkbox__label {
    margin-top: -3px;
  }

  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #4a91e9;
    border-color: #4a91e9;
  }

  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #333333;
  }

  /deep/ .el-checkbox {
    display: flex;
  }
}

input[type="checkbox"]:checked {
  background: #4A91E9;
  border: none;
}
</style>
