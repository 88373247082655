<template>
  <div class="DeliveryDetails" element-loading-text=""
       element-loading-background="rgba(0, 0, 0, 0.3)"
       v-loading.fullscreen.lock="fullscreenLoading">
    <HomePageTop/>
    <temporaryOrderDetails v-if="orderStatusName==='temporary'||orderStatusName==='offer'"
                           :orderStatusName="orderStatusName"/>
    <obligationOrderDetailsPage v-if="orderStatusName==='obligation'"/>
    <div class="technicalServiceSupport">
      <div>1688 より、一部技術、情報サポートを受けて提供させて頂いております</div>
    </div>
  </div>
</template>
<script>
import HomePageTop from "../../../../../../components/head/HomePageTop.vue";
import temporaryOrderDetails from './components/temporaryOrderDetailsPage'
import obligationOrderDetailsPage from './components/obligationOrderDetailsPage'
import FootVue from "../../../../../../components/foot/Foot.vue";

export default {
  data() {
    return {
      fullscreenLoading: true,
      orderStatusName: ''
    };
  },
  components: {
    HomePageTop,
    temporaryOrderDetails,
    obligationOrderDetailsPage,
    FootVue
  },
  computed: {},
  created() {
    this.getPorderStatus();
  },
  methods: {
    //获取订单的状态
    getPorderStatus() {
      if (this.$route.query.id) {
        this.orderStatusName = 'temporary'
        this.fullscreenLoading = false;
      } else {
        this.$api.storageDeliverDetail({porder_sn: this.$route.query.pOrder_sn}).then((res) => {
          this.fullscreenLoading = false;
          if (res.code != 0) {
            this.$message.error(this.$fanyi(res.msg));
            setTimeout(() => {
              window.history.go(-1)
            }, 1500)
            return
          }
          if (res.data === []) {
            this.$message.error(this.$fanyi(res.msg));
            return
          }
          if (res.data.porder_status >= 30) {
            this.orderStatusName = 'obligation';
          } else {
            this.orderStatusName = 'temporary';
          }
        });
      }
    }
  }
};
</script>
<style lang='scss' scoped>
@import "../../../../../../css/mixin.scss";

.technicalServiceSupport {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;

  div {
    width: 600px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    color: #333333;
    line-height: 40px;
    font-size: 16px;
    text-align: center;
  }
}
</style>
